<template>
  <div>
    <validation-provider name="full name" rules="required" v-slot="{ errors }">
      <div class="form__field-wrapper" :class="{ error: errors[0] }">
        <h3 class="form__field-label">Full Name</h3>
        <v-input
          v-model="name"
          placeholder="Enter your full name"
          class="form__field"
          autocomplete="username"
        />
        <div class="error-message form__field-error" v-if="errors[0]">
          {{ errors[0] }}
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import vInput from "@/components/ui/input";

export default {
  components: { vInput },

  props: {
    value: { type: [String, Number], default: "" },
  },

  computed: {
    name: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<template>
  <div class="contact-us">
    <h3 class="modal__title">Contact Us</h3>
    <div class="modal__body contact-us__body">
      <loader v-if="loading" :container="true" />
      <validation-observer v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" class="form">
          <v-name v-model="name" />
          <v-email v-model="email" />
          <v-phone v-model="phone" />
          <v-message v-model="message" />

          <div v-if="error" v-html="error" class="text-danger" />

          <div class="modal__footer">
            <button type="button" class="button button_text button_md" @click="onClose">cancel</button>
            <button type="submit" class="button button_secondary button_md">submit</button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/loader";
import vPhone from "@/components/formElements/phone";
import vEmail from "@/components/formElements/email";
import vName from "@/components/formElements/fullName";
import vMessage from "@/components/formElements/message";
import { ajaxContactUs } from "@/api";
import { formatErrorsToString } from "@/modules/utils";
import { POPUP_NOTIFICATION, MSG_MESSAGE_SENT } from "@/constants";
import { saveLog } from "@/modules/logger";

export default {
  components: { Loader, vPhone, vEmail, vName, vMessage },

  inject: {
    showModal: { default: () => {} },
  },

  data: () => ({
    name: "",
    email: "",
    phone: "",
    message: "",
    loading: false,
    error: null,
  }),

  mounted() {
    saveLog("pop-up-contact-us");
  },

  methods: {
    onSubmit() {
      const { name, email, phone, message } = this;

      this.loading = true;
      this.error = null;

      return ajaxContactUs({ name, email, phone, message })
        .then(this.onSuccess)
        .catch(this.errorHandler)
        .finally(this.finalHandler);
    },

    finalHandler() {
      this.loading = false;
    },

    onClose() {
      this.$emit("close");
    },

    onSuccess() {
      saveLog("contact-us-submit");
      this.onClose();
      this.showModal(POPUP_NOTIFICATION, {
        title: MSG_MESSAGE_SENT,
        icon: "check",
      });
    },

    errorHandler(error) {
      if (error?.response?.data) {
        this.error = formatErrorsToString(error.response.data);
        saveLog("contact-us-submit-error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-us {
  &__body {
    position: relative;
  }
}
</style>
